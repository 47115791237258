import { useCallback, useRef, useState, useMemo, Fragment } from "react";
import { toast } from "shared/providers/toast/toast.package";
import { useMediaQuery } from "shared/providers/react-responsive/react-responsive.package";
import { Waypoint } from "shared/providers/waypoint/waypoint.package";
import { useTheme } from "shared/providers/styled-components/styled-components.package";
import {
  useHistory,
  matchPath,
  useLocation,
  NavLink,
} from "shared/providers/router/router.package";
import { useApolloClient } from "shared/providers/apollo/apollo.package";

import useAuth from "../../hooks/useAuth.hook";
import useOnClickOutside from "shared/hooks/useOutside.hook";

import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { sidebarExpandedVar } from "../../common/apollo/vars/sidebar.var";
import {
  portfolioVar,
  resetPortfolioVar,
} from "../../common/apollo/vars/portfolio.var";
import { portfolioSwitchingVar } from "../../common/apollo/vars/portfolio-switching.var";
import { resetDockVar } from "../../common/apollo/vars/dock.var";
import { resetGlobalModalVar } from "../../common/apollo/vars/global-modal.var";

import SideBarWrapper from "./sidebar.styles";

import Title from "shared/components/title/title.styles";
import DropdownMenu from "shared/components/dropdown-menu/dropdown-menu.component";
import DropdownMenuItem from "shared/components/dropdown-menu/item/item.component";

import {
  TbBuildingSkyscraper,
  TbCheck,
  TbSettings,
  TbLock,
  TbHome,
  TbLockOpen,
  TbMail,
  TbChevronDown,
  TbCashBanknote,
  TbCircleDot,
  TbTool,
  TbX,
  TbBuilding,
  TbUser,
  TbBuildingBank,
  TbAlertTriangle,
  TbClipboardText,
  TbShieldCheck,
} from "shared/providers/react-icons/tb.package";

import { useGetPortfoliosDropdownQuery } from "../../generated/graphql";

import { BREAKPOINTS } from "shared/common/data/constants";

const SideBar = () => {
  const portfolio = useReactiveVar(portfolioVar);
  const expanded = useReactiveVar(sidebarExpandedVar);

  const switcherRef = useRef<HTMLDivElement>(null);
  const [switcherState, setSwitcherState] = useState<HTMLDivElement | null>(
    null
  );
  const [switcherOpened, setSwitcherOpened] = useState(false);

  const [expandedParent, setExpandedParent] = useState<
    "work-orders" | "incidents" | "leasing" | null
  >(null);

  const history = useHistory();
  const location = useLocation();

  const { user } = useAuth();

  const client = useApolloClient();

  const isWorkOrders = matchPath(location.pathname, {
    path: "/portfolios/:id/work-orders",
  });

  const isIncidents = matchPath(location.pathname, {
    path: "/portfolios/:id/incidents",
  });

  const isLeasing = matchPath(location.pathname, {
    path: "/portfolios/:id/leasing",
  });

  const { layout } = useTheme();

  const isMobile = useMediaQuery({
    query: `(max-width: ${parseInt(BREAKPOINTS["md"], 10) - 1}px)`,
  });

  const { data, fetchMore } = useGetPortfoliosDropdownQuery({
    onError: (err) => {
      console.log(err);
      toast.error(
        `Some portfolios might not be available in switcher. Error: ${err.message}`
      );
    },
  });

  const getMoreData = useCallback(
    () =>
      fetchMore({
        variables: {
          after: data!.getPortfolios.pagination.endCursor,
        },
      }),
    [data, fetchMore]
  );

  const closeSwitcher = useCallback(() => setSwitcherOpened(false), []);

  useOnClickOutside(switcherRef, closeSwitcher);

  const switcherItems = useMemo(
    () => {
      if (!portfolio || !data?.getPortfolios.data.length) {
        return null;
      }

      return data.getPortfolios.data.map(({ id, title, logo }, idx) => {
        const isCurrent = portfolio.id === id;

        return (
          <DropdownMenuItem
            key={id}
            active={isCurrent}
            onClick={
              isCurrent
                ? null
                : () => {
                    // First lets set loading state in app
                    portfolioSwitchingVar(true);

                    setTimeout(() => {
                      // Second lets reset portfolio data so new GQL context and headers are resetted
                      resetPortfolioVar();
                      resetDockVar();
                      resetGlobalModalVar();

                      setTimeout(() => {
                        // Finally lets clear cache and switch to new portfolio
                        client.cache.reset();

                        history.push(`/portfolios/${id}`);

                        setTimeout(() => {
                          portfolioSwitchingVar(false);
                        }, 200);
                      }, 200);
                    }, 200);
                  }
            }
          >
            {data.getPortfolios.data.length - 1 === idx &&
            data?.getPortfolios.pagination.hasNextPage &&
            data.getPortfolios.pagination.endCursor ? (
              <Waypoint onEnter={getMoreData} />
            ) : null}

            <div className="switcher-drop-logo">
              {logo?.url ? (
                <img src={logo.url} alt="Portfolio Logo" />
              ) : (
                <TbBuildingSkyscraper />
              )}
            </div>

            <span>{title}</span>

            {isCurrent ? (
              <div className="switcher-active-indicator">
                <TbCheck />
              </div>
            ) : null}
          </DropdownMenuItem>
        );
      });
    },
    // eslint-disable-next-line
    [
      data?.getPortfolios.data,
      data?.getPortfolios.pagination.hasNextPage,
      data?.getPortfolios.pagination.endCursor,
      portfolio,
      history,
      getMoreData,
    ]
  );

  return (
    <SideBarWrapper
      className={
        expanded && switcherOpened
          ? "expanded open"
          : expanded
          ? "expanded"
          : switcherOpened
          ? "open"
          : undefined
      }
    >
      <div className="title-wrapper" ref={switcherRef}>
        <TbX
          onClick={() => sidebarExpandedVar(false)}
          className="close-hamburger"
        />
        <div
          className={
            switcherOpened ? "property-switcher open" : "property-switcher"
          }
          ref={setSwitcherState}
          onClick={() =>
            switcherItems?.length
              ? setSwitcherOpened((prevState) => !prevState)
              : null
          }
        >
          <div className="logo-holder">
            {portfolio?.logo?.url ? (
              <img src={portfolio.logo.url} alt="Portfolio Logo" />
            ) : (
              <TbBuildingSkyscraper />
            )}
          </div>

          <Title level={5} as="h3">
            {portfolio?.title || "Building Hub"}
          </Title>

          {switcherItems?.length ? <TbChevronDown /> : null}
        </div>

        {switcherItems?.length && switcherOpened ? (
          <DropdownMenu
            referenceRef={switcherState}
            modifiers={{
              placement: "bottom-start",
              modifiers: [
                {
                  name: "preventOverflow",
                },
              ],
            }}
            $width={`calc(${layout.expandedWidth} - 24px)`}
            $maxHeight="200px"
          >
            {switcherItems}
          </DropdownMenu>
        ) : null}
      </div>

      <ul className="body-wrapper">
        {portfolio ? (
          <Fragment>
            {user?.viewPortfolioDashboard ? (
              <li className="single-item-wrapper">
                <NavLink
                  className="single-item"
                  exact
                  to={`/portfolios/${portfolio.id}`}
                  onClick={() => (isMobile ? sidebarExpandedVar(false) : null)}
                >
                  <TbHome />
                  <span>Dashboard</span>
                </NavLink>
              </li>
            ) : null}

            <li className="single-item-wrapper">
              <NavLink
                className="single-item"
                to={`/portfolios/${portfolio.id}/properties`}
                onClick={() => (isMobile ? sidebarExpandedVar(false) : null)}
              >
                <TbBuilding />
                <span>Properties</span>
              </NavLink>
            </li>

            <li className="single-item-wrapper">
              <NavLink
                className="single-item"
                to={`/portfolios/${portfolio.id}/companies`}
                onClick={() => (isMobile ? sidebarExpandedVar(false) : null)}
              >
                <TbBuildingSkyscraper />
                <span>Companies</span>
              </NavLink>
            </li>

            <li className="single-item-wrapper">
              <NavLink
                className="single-item"
                to={`/portfolios/${portfolio.id}/accounting`}
                onClick={() => (isMobile ? sidebarExpandedVar(false) : null)}
              >
                <TbBuildingBank />
                <span>Accounting</span>
              </NavLink>
            </li>

            <li className="single-item-wrapper">
              <NavLink
                className="single-item"
                to={`/portfolios/${portfolio.id}/notifications`}
                onClick={() => (isMobile ? sidebarExpandedVar(false) : null)}
              >
                <TbMail />
                <span>Notifications</span>
              </NavLink>
            </li>

            <li className="single-item-wrapper">
              <NavLink
                className="single-item"
                to={`/portfolios/${portfolio.id}/roles`}
                onClick={() => (isMobile ? sidebarExpandedVar(false) : null)}
              >
                <TbLock />
                <span>Roles</span>
              </NavLink>
            </li>

            <li className="single-item-wrapper">
              <NavLink
                className="single-item"
                to={`/portfolios/${portfolio.id}/labor-rates`}
                onClick={() => (isMobile ? sidebarExpandedVar(false) : null)}
              >
                <TbCashBanknote />
                <span>Labor Rates</span>
              </NavLink>
            </li>

            <li className="single-item-wrapper">
              <NavLink
                className="single-item"
                to={`/portfolios/${portfolio.id}/users`}
                onClick={() => (isMobile ? sidebarExpandedVar(false) : null)}
              >
                <TbUser />
                <span>Users</span>
              </NavLink>
            </li>

            {user?.viewPortfolioCOIs ? (
              <li className="single-item-wrapper">
                <NavLink
                  className="single-item"
                  to={`/portfolios/${portfolio.id}/coi`}
                  onClick={() => (isMobile ? sidebarExpandedVar(false) : null)}
                >
                  <TbShieldCheck />
                  <span>COI</span>
                </NavLink>
              </li>
            ) : null}

            <li className="single-item-wrapper hasSub">
              <div
                className={`${
                  isWorkOrders
                    ? "single-item hasCaret active"
                    : "single-item hasCaret"
                }`}
                onClick={() =>
                  setExpandedParent((prevState) =>
                    prevState === "work-orders" ? null : "work-orders"
                  )
                }
              >
                <TbTool />
                <span>Work Orders</span>
                <TbChevronDown
                  className={
                    expandedParent === "work-orders"
                      ? "caret caret--open"
                      : "caret"
                  }
                />
              </div>

              {expandedParent === "work-orders" ? (
                <ul>
                  {user?.viewPortfolioWorkOrders ? (
                    <li className="single-item-wrapper">
                      <NavLink
                        exact
                        className="single-item"
                        to={`/portfolios/${portfolio.id}/work-orders`}
                        onClick={() =>
                          isMobile ? sidebarExpandedVar(false) : null
                        }
                      >
                        <TbCircleDot />
                        <span>All Records</span>
                      </NavLink>
                    </li>
                  ) : null}

                  <li className="single-item-wrapper">
                    <NavLink
                      className="single-item"
                      to={`/portfolios/${portfolio.id}/work-orders/issue-types`}
                      onClick={() =>
                        isMobile ? sidebarExpandedVar(false) : null
                      }
                    >
                      <TbCircleDot />
                      <span>Issue Types</span>
                    </NavLink>
                  </li>

                  <li className="single-item-wrapper">
                    <NavLink
                      className="single-item"
                      to={`/portfolios/${portfolio.id}/work-orders/issue-types-groups`}
                      onClick={() =>
                        isMobile ? sidebarExpandedVar(false) : null
                      }
                    >
                      <TbCircleDot />
                      <span>Issue Types - Groups</span>
                    </NavLink>
                  </li>

                  <li className="single-item-wrapper">
                    <NavLink
                      className="single-item"
                      to={`/portfolios/${portfolio.id}/work-orders/priorities`}
                      onClick={() =>
                        isMobile ? sidebarExpandedVar(false) : null
                      }
                    >
                      <TbCircleDot />
                      <span>Priorities</span>
                    </NavLink>
                  </li>

                  <li className="single-item-wrapper">
                    <NavLink
                      className="single-item"
                      to={`/portfolios/${portfolio.id}/work-orders/statuses`}
                      onClick={() =>
                        isMobile ? sidebarExpandedVar(false) : null
                      }
                    >
                      <TbCircleDot />
                      <span>Statuses</span>
                    </NavLink>
                  </li>

                  <li className="single-item-wrapper">
                    <NavLink
                      className="single-item"
                      to={`/portfolios/${portfolio.id}/work-orders/settings`}
                      onClick={() =>
                        isMobile ? sidebarExpandedVar(false) : null
                      }
                    >
                      <TbCircleDot />
                      <span>Settings</span>
                    </NavLink>
                  </li>
                </ul>
              ) : null}
            </li>

            <li className="single-item-wrapper hasSub">
              <div
                className={`${
                  isIncidents
                    ? "single-item hasCaret active"
                    : "single-item hasCaret"
                }`}
                onClick={() =>
                  setExpandedParent((prevState) =>
                    prevState === "incidents" ? null : "incidents"
                  )
                }
              >
                <TbAlertTriangle />
                <span>Incidents</span>
                <TbChevronDown
                  className={
                    expandedParent === "incidents"
                      ? "caret caret--open"
                      : "caret"
                  }
                />
              </div>

              {expandedParent === "incidents" ? (
                <ul>
                  <li className="single-item-wrapper">
                    <NavLink
                      className="single-item"
                      to={`/portfolios/${portfolio.id}/incidents/issue-types`}
                      onClick={() =>
                        isMobile ? sidebarExpandedVar(false) : null
                      }
                    >
                      <TbCircleDot />
                      <span>Issue Types</span>
                    </NavLink>
                  </li>

                  <li className="single-item-wrapper">
                    <NavLink
                      className="single-item"
                      to={`/portfolios/${portfolio.id}/incidents/issue-types-groups`}
                      onClick={() =>
                        isMobile ? sidebarExpandedVar(false) : null
                      }
                    >
                      <TbCircleDot />
                      <span>Issue Types - Groups</span>
                    </NavLink>
                  </li>

                  <li className="single-item-wrapper">
                    <NavLink
                      className="single-item"
                      to={`/portfolios/${portfolio.id}/incidents/statuses`}
                      onClick={() =>
                        isMobile ? sidebarExpandedVar(false) : null
                      }
                    >
                      <TbCircleDot />
                      <span>Statuses</span>
                    </NavLink>
                  </li>

                  <li className="single-item-wrapper">
                    <NavLink
                      className="single-item"
                      to={`/portfolios/${portfolio.id}/incidents/settings`}
                      onClick={() =>
                        isMobile ? sidebarExpandedVar(false) : null
                      }
                    >
                      <TbCircleDot />
                      <span>Settings</span>
                    </NavLink>
                  </li>
                </ul>
              ) : null}
            </li>

            <li className="single-item-wrapper hasSub">
              <div
                className={`${
                  isLeasing
                    ? "single-item hasCaret active"
                    : "single-item hasCaret"
                }`}
                onClick={() =>
                  setExpandedParent((prevState) =>
                    prevState === "leasing" ? null : "leasing"
                  )
                }
              >
                <TbClipboardText />
                <span>Leasing</span>
                <TbChevronDown
                  className={
                    expandedParent === "leasing" ? "caret caret--open" : "caret"
                  }
                />
              </div>

              {expandedParent === "leasing" ? (
                <ul>
                  <li className="single-item-wrapper">
                    <NavLink
                      className="single-item"
                      to={`/portfolios/${portfolio.id}/leasing/charge-types`}
                      onClick={() =>
                        isMobile ? sidebarExpandedVar(false) : null
                      }
                    >
                      <TbCircleDot />
                      <span>Charge Types</span>
                    </NavLink>
                  </li>
                </ul>
              ) : null}
            </li>

            <li className="single-item-wrapper">
              <NavLink
                className="single-item"
                to={`/portfolios/${portfolio.id}/settings`}
                onClick={() => (isMobile ? sidebarExpandedVar(false) : null)}
              >
                <TbSettings />
                <span>Settings</span>
              </NavLink>
            </li>
          </Fragment>
        ) : (
          <Fragment>
            <li className="single-item-wrapper">
              <NavLink
                className="single-item"
                to="/portfolios"
                onClick={() => (isMobile ? sidebarExpandedVar(false) : null)}
              >
                <TbBuildingSkyscraper />
                <span>Portfolios</span>
              </NavLink>
            </li>

            <li className="single-item-wrapper">
              <NavLink
                className="single-item"
                to="/properties"
                onClick={() => (isMobile ? sidebarExpandedVar(false) : null)}
              >
                <TbBuilding />
                <span>Properties</span>
              </NavLink>
            </li>

            {user?.globalEmailAccess ? (
              <li className="single-item-wrapper">
                <NavLink
                  className="single-item"
                  to="/notifications"
                  onClick={() => (isMobile ? sidebarExpandedVar(false) : null)}
                >
                  <TbMail />
                  <span>Global Notifications</span>
                </NavLink>
              </li>
            ) : null}

            {user?.controlGlobalUsers ? (
              <li className="single-item-wrapper">
                <NavLink
                  className="single-item"
                  to="/users"
                  onClick={() => (isMobile ? sidebarExpandedVar(false) : null)}
                >
                  <TbUser />
                  <span>Global Users</span>
                </NavLink>
              </li>
            ) : null}
          </Fragment>
        )}
      </ul>

      <ul className="footer-wrapper">
        <li className="single-item-wrapper hide-under-md">
          <div
            className="single-item"
            onClick={() => sidebarExpandedVar(!expanded)}
          >
            {expanded ? <TbLockOpen /> : <TbLock />}
            <span>{expanded ? "Unlock" : "Lock"} menu</span>
          </div>
        </li>

        {portfolio ? (
          <li className="single-item-wrapper">
            <div
              className="single-item"
              onClick={() => history.push("/portfolios")}
            >
              <TbSettings />
              <span>Go to all portfolios</span>
            </div>
          </li>
        ) : null}
      </ul>
    </SideBarWrapper>
  );
};

export default SideBar;
