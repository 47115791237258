import { useCallback } from "react";

import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import {
  dockRightPositionVar,
  dockVar,
} from "../../common/apollo/vars/dock.var";
import { globalModalVar } from "../../common/apollo/vars/global-modal.var";

import SharedDock from "shared/components/dock/dock.component";

import { IDockMinimizedItem } from "shared/components/dock/dock.interfaces";

const Dock = () => {
  const minimizedItems = useReactiveVar(dockVar);
  const rightPosition = useReactiveVar(dockRightPositionVar);

  const openItem = useCallback(
    ({ id, type }: IDockMinimizedItem) =>
      globalModalVar({
        type,
        id,
      }),
    []
  );

  const removeItem = useCallback(({ id }: IDockMinimizedItem) => {
    const newItems = [...dockVar()],
      idx = newItems.findIndex((singleItem) => singleItem.id === id);

    if (idx > -1) {
      newItems.splice(idx, 1);
    }

    dockVar(newItems);
  }, []);

  if (!minimizedItems.length) {
    return null;
  }

  return (
    <SharedDock
      minimizedItems={minimizedItems}
      onClick={openItem}
      onRemove={removeItem}
      rightPosition={rightPosition}
    />
  );
};

export default Dock;
