import { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { toast } from "shared/providers/toast/toast.package";
import { useHistory, useParams } from "shared/providers/router/router.package";

import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import {
  portfolioVar,
  resetPortfolioVar,
} from "../../common/apollo/vars/portfolio.var";
import { portfolioSwitchingVar } from "../../common/apollo/vars/portfolio-switching.var";
import { resetDockVar } from "../../common/apollo/vars/dock.var";
import { resetGlobalModalVar } from "../../common/apollo/vars/global-modal.var";

import Spinner from "shared/components/spinner/spinner.component";

import { useGetPortfolioLazyQuery } from "../../generated/graphql";

const PortfolioProtectedRoute = ({ children }: PropsWithChildren) => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const portfolioSwitching = useReactiveVar(portfolioSwitchingVar);

  const [portfolioSet, setPortfolio] = useState(false);

  const [getData] = useGetPortfolioLazyQuery({
    onCompleted: (data) => {
      portfolioVar({
        id: data.getPortfolio.id,
        title: data.getPortfolio.title,
        url: data.getPortfolio.url,
        active: data.getPortfolio.active,
        logo: data.getPortfolio.logo || null,
        background: data.getPortfolio.background || null,
      });

      setPortfolio(true);
    },
    onError: (err) => {
      toast.error(err.message);

      history.push("/portfolios");
    },
  });

  useEffect(() => {
    return () => {
      resetDockVar();
      resetGlobalModalVar();
      resetPortfolioVar();
    };
  }, []);

  useEffect(() => {
    setPortfolio(false);

    if (!portfolioSwitching) {
      getData({
        fetchPolicy: "no-cache",
        variables: {
          id,
        },
      });
    }
  }, [id, getData, portfolioSwitching]);

  if (portfolioSwitching || !portfolioSet) {
    return <Spinner $height="100vh" />;
  }

  return <Fragment>{children}</Fragment>;
};

export default PortfolioProtectedRoute;
