import { PropsWithChildren } from "react";
import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { sidebarExpandedVar } from "../../common/apollo/vars/sidebar.var";

import Topbar from "../../components/topbar/topbar.component";
import Sidebar from "../../components/sidebar/sidebar.component";

import Main from "shared/components/layout/main/main.component";
import SharedLayout from "shared/components/layout/layout.styles";

const Layout = ({ children }: PropsWithChildren) => {
  const expanded = useReactiveVar(sidebarExpandedVar);

  return (
    <SharedLayout expanded={expanded}>
      <Topbar />
      <Sidebar />

      <Main>{children}</Main>
    </SharedLayout>
  );
};

export default Layout;
