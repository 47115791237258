import { createElement } from "react";

import LabelWrapper from "./label.styles";
import Description from "../../description/description.styles";
import Tooltip from "../../tooltip/tooltip.component";

import { ILabelProps } from "./label.interfaces";

const Label = ({
  actions,
  required,
  children,
  description,
  secondaryColor,
  tooltip,
  icon,
  className = "mb-8",
  ...props
}: ILabelProps) => {
  return (
    <LabelWrapper secondaryColor={secondaryColor} className={className}>
      <div className="label-container">
        <label {...props}>
          {icon ? createElement(icon) : null}
          <span className="label-container__text">
            {children}
            {required && <span className="required">*</span>}
          </span>
        </label>

        {tooltip ? <Tooltip showInformer>{tooltip}</Tooltip> : null}

        {actions ? <div className="ml-auto">{actions}</div> : null}
      </div>

      {description ? <Description as="span">{description}</Description> : null}
    </LabelWrapper>
  );
};

export default Label;
