import { useReactiveVar } from "shared/providers/apollo/apollo.package";

import { sidebarExpandedVar } from "../../common/apollo/vars/sidebar.var";
import { titleVar } from "../../common/apollo/vars/title.var";

import useAuth from "../../hooks/useAuth.hook";
import useLogout from "../../hooks/useLogout.hook";

import SharedTopbar from "shared/components/layout/topbar/topbar.component";

const Topbar = () => {
  const titleData = useReactiveVar(titleVar);

  const { user } = useAuth();
  const logout = useLogout();

  return (
    <SharedTopbar
      titleComponent={titleData.titleComponent}
      titleComponentProps={titleData.titleComponentProps}
      titleComponentLabel={titleData.titleComponentLabel}
      title={titleData.title}
      titleIcon={titleData.icon}
      userFirstName={user?.firstName || ""}
      userLastName={user?.lastName || ""}
      userPhoto={user?.photo?.url}
      userTitle={user?.title || ""}
      setExpanded={sidebarExpandedVar}
      logout={logout}
    />
  );
};

export default Topbar;
