import React, { useEffect } from "react";

import { SELECT_CLASS_NAME } from "../common/data/constants";

/**
 * Control click outside the ref
 * @param ref target which we control outside click
 * @param handler ideally useCallback function handling our outside click
 */

const useOnClickOutside = (
  ref: React.RefObject<HTMLElement>,
  handler: () => void
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // We don't want to close our modals for example if user clicks on Toaster or Dropdown Menu
      if (
        (event.target as Element).closest(".Toastify") ||
        (event.target as Element).closest(`.${SELECT_CLASS_NAME}__menu-portal`)
      ) {
        return;
      }

      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler();
    };

    document.addEventListener("mousedown", listener, true);
    document.addEventListener("touchstart", listener, true);
    return () => {
      document.removeEventListener("mousedown", listener, true);
      document.removeEventListener("touchstart", listener, true);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
