import React, { useMemo, useState } from "react";
import { createRoot } from "react-dom/client";

import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
} from "shared/providers/apollo/apollo.package";
import apolloCache from "./common/apollo/cache";
import apolloHttpLink from "./common/apollo/http-link";
import apolloAuthLink from "./common/apollo/auth-link";
import apolloErrorLink from "./common/apollo/error-link";

import StylesProvider from "shared/providers/styles.provider";
import RouterProvider from "shared/providers/router/router.provider";
import DayjsProvider from "shared/providers/dayjs/dajys.provider";

import App from "./components/app/app.component";

import reportWebVitals from "./reportWebVitals";

const RootComponent = () => {
  const [isMaintenance, setIsMaintenance] = useState(false);

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          apolloErrorLink(setIsMaintenance),
          ApolloLink.from([apolloAuthLink, apolloHttpLink]),
        ]),
        cache: apolloCache,
      }),
    []
  );

  return (
    <React.StrictMode>
      <StylesProvider>
        <ApolloProvider client={apolloClient}>
          <RouterProvider>
            <DayjsProvider>
              <App isMaintenance={isMaintenance} />
            </DayjsProvider>
          </RouterProvider>
        </ApolloProvider>
      </StylesProvider>
    </React.StrictMode>
  );
};

const root = createRoot(document.getElementById("app")!);

root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
