import styled from "styled-components";

import Description from "../../description/description.styles";

import { ILabelProps } from "./label.interfaces";

import rem from "../../../utils/rem";

const LabelWrapper = styled.div<ILabelProps>`
  max-width: 100%;

  .label-container {
    display: flex;
    align-items: center;
  }

  label {
    font-size: ${rem(14)};
    text-transform: none;
    padding: 0;
    color: ${({ theme, secondaryColor }) =>
      secondaryColor ? theme.colors.grey[600] : theme.colors.grey[800]};
    font-weight: 500;
    display: inline-flex;
    align-items: center;

    > svg {
      flex-shrink: 0;
      font-size: ${rem(16)};
      margin-right: 5px;
    }

    > .label-container__text > .required {
      color: ${({ theme }) => theme.colors.warning.base};
      margin-left: 5px;
    }
  }

  ${Description} {
    display: block;
    margin-top: 5px;
    font-size: 12px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export default LabelWrapper;
