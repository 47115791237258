import { makeVar } from "shared/providers/apollo/apollo.package";

// object vs Record<string, unknown>: https://stackoverflow.com/a/66606826

export interface ITitleVar<P extends object = object> {
  titleComponent?: React.ComponentType<P> | null;
  titleComponentProps?: P | null;
  titleComponentLabel?: string | null;
  title?: string | null;
  icon?: string | null;
}

/**
 * Control topbar title and icon
 * @param {titleComponent} - If provided always has precedence over title field
 */

export const titleVar = makeVar<ITitleVar>({
  icon: "home",
  title: "Properties",
});

/**
 * Typed function to set titleVar as makeVar does not provide generic utilities in moment of writing
 */

export const setTypedTitleVar = <P extends object>(data: ITitleVar<P>) =>
  titleVar(data as ITitleVar<object>);

/**
 * Reset titleVar to defaults
 */
export const resetTitleVar = () =>
  titleVar({
    icon: "home",
    title: "Properties",
  });
