import {
  Fragment,
  createElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import useOnClickOutside from "../../../hooks/useOutside.hook";

import Avatar from "../../avatar/avatar.component";
import Description from "../../description/description.styles";
import DropdownMenu from "../../dropdown-menu/dropdown-menu.component";
import DropdownMenuItem from "shared/components/dropdown-menu/item/item.component";
import Title from "../../title/title.styles";
import Label from "../../form/label/label.component";

import TopBarWrapper from "./topbar.styles";

import { TbChevronDown, TbBell, TbLogout, TbMenu2 } from "react-icons/tb";

import { ITopbarProps } from "./topbar.interfaces";

import getIcon from "../../../utils/get-icon";

/**
 * Control topbar title and icon
 * @param {titleComponent} - If provided always has precedence over title field
 */

const TopBar = <P extends object>({
  titleComponent: TitleComponent,
  titleComponentProps,
  titleComponentLabel,
  title,
  titleIcon,
  userFirstName,
  userLastName,
  userTitle,
  userPhoto,
  inactive,
  setExpanded,
  logout,
  items,
  hasNewNotifications,
  notifications,
}: ITopbarProps<P>) => {
  const notificationsRef = useRef<HTMLDivElement>(null);
  const userActionsRef = useRef<HTMLDivElement>(null);

  const [notificationsState, setNotificationsState] =
    useState<HTMLDivElement | null>(null);
  const [userActionsState, setUserActionsState] =
    useState<HTMLDivElement | null>(null);

  const [notificationsOpened, setNotificationsOpened] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);

  const closeNotifications = useCallback(
    () => (notificationsOpened ? setNotificationsOpened(false) : null),
    [notificationsOpened]
  );
  const closeUserMenu = useCallback(
    () => (menuOpened ? setMenuOpened(false) : null),
    [menuOpened]
  );

  useOnClickOutside(notificationsRef, closeNotifications);
  useOnClickOutside(userActionsRef, closeUserMenu);

  const onLogoutClick = useCallback(() => {
    logout();
    closeUserMenu();
  }, [logout, closeUserMenu]);

  const titleIconComponent = useMemo(() => getIcon(titleIcon), [titleIcon]);

  return (
    <TopBarWrapper>
      {inactive ? null : (
        <div className="title-wrapper">
          <span className="mobile-hamburger" onClick={() => setExpanded(true)}>
            <TbMenu2 />
          </span>

          {TitleComponent ? (
            <div className="display-flex display-flex--a-center">
              {titleComponentLabel ? (
                <Label className="mr-5">{titleComponentLabel}</Label>
              ) : null}

              <TitleComponent {...((titleComponentProps ?? {}) as P)} />
            </div>
          ) : title ? (
            <Title level={3}>
              {titleIconComponent ? createElement(titleIconComponent) : null}
              <span>{title}</span>
            </Title>
          ) : null}
        </div>
      )}

      <div className="actions-wrapper">
        {inactive || !notifications ? null : (
          <div
            className="notifications-actions-wrapper "
            ref={notificationsRef}
          >
            <div
              ref={setNotificationsState}
              className={`notifications-actions${
                notificationsOpened ? " open" : ""
              }`}
              onClick={() => setNotificationsOpened((prevState) => !prevState)}
            >
              <TbBell />

              <div
                className={
                  hasNewNotifications
                    ? "new-notification-indicator visible"
                    : "new-notification-indicator"
                }
              />
            </div>

            {notificationsOpened ? (
              <DropdownMenu
                as="div"
                referenceRef={notificationsState}
                className="pt-0 pr-0 pb-0 pl-0"
              >
                {notifications}
              </DropdownMenu>
            ) : null}
          </div>
        )}

        <div ref={userActionsRef}>
          <div
            ref={setUserActionsState}
            onClick={() => setMenuOpened((prevState) => !prevState)}
            className={`user-actions${menuOpened ? " open" : ""}`}
          >
            <Avatar $size="m" $bgImg={userPhoto} className="mr-12">
              {userPhoto
                ? null
                : `${userFirstName.charAt(0)}${userLastName.charAt(0)}`}
            </Avatar>

            <div className="user-text">
              <Title as="h6" level={6}>
                {`${userFirstName} ${userLastName}`}
              </Title>

              {userTitle ? <Description>{userTitle}</Description> : null}
            </div>

            <TbChevronDown />
          </div>

          {menuOpened ? (
            <DropdownMenu
              as="div"
              referenceRef={userActionsState}
              className="pt-0 pr-0 pb-0 pl-0"
              $width="200px"
            >
              {inactive || !items?.length ? null : (
                <ul className="user-menu">
                  {items.map((singleItem, idx) => (
                    <DropdownMenuItem
                      key={idx}
                      onClick={singleItem.onClick}
                      icon={singleItem.icon}
                    >
                      {singleItem.title}
                    </DropdownMenuItem>
                  ))}
                </ul>
              )}

              <div className="user-logout-wrapper">
                <DropdownMenuItem as="div" onClick={onLogoutClick}>
                  <TbLogout /> Logout
                </DropdownMenuItem>
              </div>
            </DropdownMenu>
          ) : null}
        </div>
      </div>
    </TopBarWrapper>
  );
};

export default TopBar;
