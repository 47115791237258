import {
  TbActivity,
  TbAdjustments,
  TbAlertTriangle,
  TbArmchair,
  TbArrowsRightLeft,
  TbArrowsUpDown,
  TbBatteryCharging,
  TbBluetooth,
  TbBolt,
  TbBook2,
  TbBookmark,
  TbBucket,
  TbBuilding,
  TbBuildingBank,
  TbBuildingSkyscraper,
  TbBulb,
  TbCalculator,
  TbCalendar,
  TbCashBanknote,
  TbCast,
  TbChartBar,
  TbCircleCheck,
  TbClipboard,
  TbClipboardText,
  TbCoffee,
  TbCreditCard,
  TbCup,
  TbCurrencyDollar,
  TbDatabase,
  TbDeviceDesktop,
  TbDeviceMobile,
  TbDeviceProjector,
  TbDevices,
  TbDoor,
  TbExternalLink,
  TbEye,
  TbFile,
  TbHistory,
  TbHome,
  TbKeyboard,
  TbLink,
  TbLock,
  TbMail,
  TbMenuOrder,
  TbMug,
  TbNews,
  TbOutlet,
  TbPdf,
  TbPrinter,
  TbReport,
  TbSend,
  TbSettings,
  TbShieldCheck,
  TbShoppingCart,
  TbStack2,
  TbStarHalfFilled,
  TbTag,
  TbTeapot,
  TbTemperatureMinus,
  TbTemperaturePlus,
  TbTemplate,
  TbTicket,
  TbTool,
  TbTools,
  TbUser,
  TbUsers,
  TbWifi,
} from "react-icons/tb";

// NOTE: Whenever changing anything in icons make sure to adjust also in containers/icon-select/icon-select.utils

const icons = [
  {
    id: "home",
    icon: TbHome,
  },
  {
    id: "link",
    icon: TbLink,
  },
  {
    id: "externalLink",
    icon: TbExternalLink,
  },
  {
    id: "activity",
    icon: TbActivity,
  },
  {
    id: "building",
    icon: TbBuildingSkyscraper,
  },
  {
    id: "buildingSecondary",
    icon: TbBuilding,
  },
  {
    id: "lock",
    icon: TbLock,
  },
  {
    id: "menuSettings",
    icon: TbMenuOrder,
  },
  {
    id: "person",
    icon: TbUser,
  },
  {
    id: "wrench",
    icon: TbTool,
  },
  {
    id: "star",
    icon: TbStarHalfFilled,
  },
  {
    id: "warningAmber",
    icon: TbAlertTriangle,
  },
  {
    id: "integration",
    icon: TbArrowsRightLeft,
  },
  {
    id: "exchange",
    icon: TbArrowsUpDown,
  },
  {
    id: "eye",
    icon: TbEye,
  },
  {
    id: "pdf",
    icon: TbPdf,
  },
  {
    id: "accountBalance",
    icon: TbBuildingBank,
  },
  {
    id: "multiplePersons",
    icon: TbUsers,
  },
  {
    id: "moneyBill",
    icon: TbCashBanknote,
  },
  {
    id: "newspaper",
    icon: TbNews,
  },
  {
    id: "dollar",
    icon: TbCurrencyDollar,
  },
  {
    id: "priceTag",
    icon: TbTag,
  },
  {
    id: "tools",
    icon: TbTools,
  },
  {
    id: "certificate",
    icon: TbShieldCheck,
  },
  {
    id: "printer",
    icon: TbPrinter,
  },
  {
    id: "devices",
    icon: TbDevices,
  },
  {
    id: "chair",
    icon: TbArmchair,
  },
  {
    id: "doors",
    icon: TbDoor,
  },
  {
    id: "book",
    icon: TbBook2,
  },
  {
    id: "history",
    icon: TbHistory,
  },
  {
    id: "report",
    icon: TbReport,
  },
  {
    id: "phone",
    icon: TbDeviceMobile,
  },
  {
    id: "email",
    icon: TbMail,
  },
  {
    id: "document",
    icon: TbFile,
  },
  {
    id: "template",
    icon: TbTemplate,
  },
  {
    id: "cart",
    icon: TbShoppingCart,
  },
  {
    id: "send",
    icon: TbSend,
  },
  {
    id: "calendar",
    icon: TbCalendar,
  },
  {
    id: "stack",
    icon: TbStack2,
  },
  {
    id: "adjust",
    icon: TbAdjustments,
  },
  {
    id: "chart",
    icon: TbChartBar,
  },
  {
    id: "wifi",
    icon: TbWifi,
  },
  {
    id: "coffee",
    icon: TbCoffee,
  },
  {
    id: "outlet",
    icon: TbOutlet,
  },
  {
    id: "highTemp",
    icon: TbTemperaturePlus,
  },
  {
    id: "lowTemp",
    icon: TbTemperatureMinus,
  },
  {
    id: "checkmark",
    icon: TbCircleCheck,
  },
  {
    id: "airplay",
    icon: TbCast,
  },
  {
    id: "card",
    icon: TbCreditCard,
  },
  {
    id: "charging",
    icon: TbBatteryCharging,
  },
  {
    id: "ticket",
    icon: TbTicket,
  },
  {
    id: "electricity",
    icon: TbBolt,
  },
  {
    id: "bluetooth",
    icon: TbBluetooth,
  },
  {
    id: "keyboard",
    icon: TbKeyboard,
  },
  {
    id: "calculator",
    icon: TbCalculator,
  },
  {
    id: "coffePot",
    icon: TbTeapot,
  },
  {
    id: "broom",
    icon: TbBucket,
  },
  {
    id: "clipboard",
    icon: TbClipboard,
  },
  {
    id: "clipboardText",
    icon: TbClipboardText,
  },
  {
    id: "tea",
    icon: TbMug,
  },
  {
    id: "foodBeverages",
    icon: TbCup,
  },
  {
    id: "lightbulb",
    icon: TbBulb,
  },
  {
    id: "projector",
    icon: TbDeviceProjector,
  },
  {
    id: "screen",
    icon: TbDeviceDesktop,
  },
  {
    id: "bookmark",
    icon: TbBookmark,
  },
  {
    id: "database",
    icon: TbDatabase,
  },
  {
    id: "cog",
    icon: TbSettings,
  },
];

export default icons;
