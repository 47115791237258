import { getLocalStorageItem } from "shared/utils/local-storage";
import { WEB_AUTH_ADMIN_TOKEN } from "shared/common/data/constants";

import { MeQuery, useMeQuery } from "../generated/graphql";

export interface IAuthUser {
  user?: MeQuery["me"];
  loading?: boolean;
}

/**
 * Get current logged user
 * @returns logged user object
 */

const useAuth = (): IAuthUser => {
  const token = getLocalStorageItem(WEB_AUTH_ADMIN_TOKEN);

  const { data, loading } = useMeQuery({
    skip: !token,
  });

  return {
    user: data?.me,
    loading: loading,
  };
};

export default useAuth;
